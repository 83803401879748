import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { resetNotification } from "../reducers/notificationReducer";
import Notification from "./Notification";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ConfirmationNotification = () => {
  const { notification } = useSelector((state) => state.notification);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(resetNotification());
  };

  return (
    <Modal show={notification} onHide={handleClose} animation={false}>
      <Notification
        title={t("Notification")}
        handleClose={handleClose}
        message={notification}
        type="Notification"
      />
    </Modal>
  );
};

export default ConfirmationNotification;
