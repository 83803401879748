import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const ResendPassword = ({ onClick }) => {
  const { t } = useTranslation();
  const [phone, setPhone] = useState("");

  return (
    <div className="resend-password">
      <div className="resend-password__title">{t("Resend password")}</div>
      <div className="resend-password__line">
        <label className="resend-password__label">{t("Phone number")}</label>
        <div className="resend-password__element">
          <input
            type="text"
            value={phone}
            onChange={(event) => setPhone(event.target.value)}
          ></input>
        </div>
      </div>
      <button
        className={`button resend-password__submit ${
          phone.length === 0 ? "disabled" : ""
        }`}
        onClick={() => onClick(phone)}
      >
        {t("Resend")}
      </button>
    </div>
  );
};

export default ResendPassword;
