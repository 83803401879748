import React from "react";
import { useTranslation } from "react-i18next";
import { SIGNED_AGREEMENT_API } from "../config/config";

const SignedAgreement = () => {
  const { t } = useTranslation();
  return (
    <div>
      {t("Signed agreement can be viewed here:")}
      <div className="confirmation__button">
        <a
          target="_blank"
          href={SERVER_URL + APP_PREFIX + SIGNED_AGREEMENT_API}
          rel="noreferrer"
          className="button button__link"
        >
          {t("Signed agreement")}
        </a>
      </div>
    </div>
  );
};

export default SignedAgreement;
