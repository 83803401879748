import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import registrationReducer from "./reducers/registrationReducer";
import passwordRecoveryReducer from "./reducers/passwordRecoveryReducer";
import addUserReducer from "./reducers/addUserReducer";
import authProvidersReducer from "./reducers/authProvidersReducer";
import errorReducer from "./reducers/errorReducer";
import notificationReducer from "./reducers/notificationReducer";
import languageReducer from "./reducers/languageReducer";

const reducer = combineReducers({
  registration: registrationReducer,
  passwordRecovery: passwordRecoveryReducer,
  addUser: addUserReducer,
  authProviders: authProvidersReducer,
  language: languageReducer,
  notification: notificationReducer,
  error: errorReducer,
});

const store = createStore(reducer, undefined, applyMiddleware(thunk));

export default store;
