import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const ValidationCode = ({ onClick }) => {
  const { t } = useTranslation();
  const [validationCode, setValidationCode] = useState("");

  return (
    <div className="recover-password-form">
      <div className="recover-password-form__line">
        <label className="recover-password-form__label">
          {t("Validation code")}
        </label>
        <div className="recover-password-form__element">
          <input
            type="text"
            value={validationCode}
            onChange={(event) => setValidationCode(event.target.value)}
          ></input>
        </div>
      </div>

      <button
        onClick={() => onClick(validationCode)}
        className={`button recover-password-form__submit ${
          validationCode.length === 0 ? "disabled" : ""
        }`}
      >
        {t("Send")}
      </button>
    </div>
  );
};

export default ValidationCode;
