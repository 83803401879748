import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import registrationService from "../services/registration";
import {
  userDataRequest,
  userDataReceived,
  userDataError,
  userDataValidationError,
  resetUser,
} from "../reducers/addUserReducer";
import Loading from "./Loading";
import AddUserForm from "./AddUserForm";

const AddUser = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user, isFetching } = useSelector((state) => state.addUser);

  const handleSubmit = async (values) => {
    try {
      dispatch(userDataRequest());
      const data = await registrationService.addUser(values);
      dispatch(userDataReceived(data));
    } catch (error) {
      if (
        error.response &&
        error.response.status === 422 &&
        error.response?.data?.message
      ) {
        dispatch(userDataValidationError());
        return error.response.data.message;
      }
      dispatch(userDataError(error));
    }
  };

  return (
    <>
      {isFetching && <Loading />}
      <div className="divider divider_top">
        <h1>{t("User registration")}</h1>
      </div>
      <div className="container">
        <AddUserForm
          onSubmit={handleSubmit}
          initialValues={user ? user : {}}
          onReset={() => dispatch(resetUser())}
        />
      </div>
    </>
  );
};

export default AddUser;
