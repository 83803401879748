import axios from "axios";
import {
  CHECK_REGISTRATION_STATE_API,
  FETCH_USER_DATA_API,
  AGREEMENT_API,
  SIGN_AGREEMENT_API,
  AGREEMENT_STATUS_API,
  //AGREEMENT_STATUS_API,
  AUTH_PROVIDERS_API,
  CONFIRM_PASSWORD_API,
  SEND_PASSWORD_API,
  RESEND_PASSWORD_API,
  PASSWORD_RECOVER_API,
  CONFIRM_VALIDATION_CODE_API,
  ADD_USER_API,
  DELETE_USER_API,
  LANGUAGE_API,
} from "../config/config";

//axios.defaults.withCredentials = true;

const checkRegistrationState = async () => {
  const response = await axios.post(
    SERVER_URL + APP_PREFIX + CHECK_REGISTRATION_STATE_API
  );
  return response.data;
};

const fetchUserData = async (data) => {
  const url = SERVER_URL + APP_PREFIX + FETCH_USER_DATA_API;
  const response = await axios.post(url, data);
  return response.data;
};

const fetchAuthProviders = async () => {
  const response = await axios.get(
    SERVER_URL + APP_PREFIX + AUTH_PROVIDERS_API
  );
  return response.data;
};

const fetchAgreementDocument = async () => {
  const response = await axios.get(SERVER_URL + APP_PREFIX + AGREEMENT_API);
  return response.data;
};

const signAgreement = async ({ authProvider }) => {
  const response = await axios.post(
    SERVER_URL + APP_PREFIX + SIGN_AGREEMENT_API,
    {
      authProvider,
    }
  );
  return response.data;
};

const checkAgreementStatus = async () => {
  const response = await axios.post(
    SERVER_URL + APP_PREFIX + AGREEMENT_STATUS_API
    /*{},
    {
      timeout: TIMEOUT*1000,
    }*/
  );
  return response.data;
};

const confirmPassword = async (password) => {
  const response = await axios.post(
    SERVER_URL + APP_PREFIX + CONFIRM_PASSWORD_API,
    {
      password,
    }
  );
  return response.data;
};

const sendPassword = async () => {
  const response = await axios.post(
    SERVER_URL + APP_PREFIX + SEND_PASSWORD_API
  );
  return response.data;
};

const resendPassword = async (phone) => {
  const response = await axios.post(
    SERVER_URL + APP_PREFIX + RESEND_PASSWORD_API,
    {
      phone,
    }
  );
  return response.data;
};

const getUserPhone = async (data) => {
  const response = await axios.get(
    SERVER_URL + APP_PREFIX + PASSWORD_RECOVER_API,
    {
      params: {
        taxnumber: data.taxnumber,
      },
    }
  );
  return response.data;
};

const sendValidationCode = async () => {
  const response = await axios.post(
    SERVER_URL + APP_PREFIX + PASSWORD_RECOVER_API
  );
  return response.data;
};

const confirmValidationCode = async (validationCode) => {
  const response = await axios.post(
    SERVER_URL + APP_PREFIX + CONFIRM_VALIDATION_CODE_API,
    {
      validationCode,
    }
  );
  return response.data;
};

const addUser = async (data) => {
  const response = await axios.post(
    SERVER_URL + APP_PREFIX + ADD_USER_API,
    data
  );
  return response.data;
};

const deleteUser = async () => {
  const response = await axios.delete(
    SERVER_URL + APP_PREFIX + DELETE_USER_API
  );
  return response.data;
};

const getLanguage = async () => {
  const response = await axios.get(SERVER_URL + APP_PREFIX + LANGUAGE_API);
  return response.data;
};

const setLanguage = async ({ language }) => {
  const response = await axios.get(
    SERVER_URL + APP_PREFIX + LANGUAGE_API + `/${language}`
  );
  return response.data;
};

const registrationService = {
  checkRegistrationState,
  fetchUserData,
  fetchAgreementDocument,
  fetchAuthProviders,
  signAgreement,
  checkAgreementStatus,
  confirmPassword,
  sendPassword,
  resendPassword,
  getUserPhone,
  sendValidationCode,
  confirmValidationCode,
  addUser,
  deleteUser,
  getLanguage,
  setLanguage,
};

export default registrationService;
