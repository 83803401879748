import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import registrationService from "../services/registration";
import {
  userDataRequest,
  userDataReceived,
  userDataError,
  sendValidationCode,
  confirmValidationCode,
} from "../reducers/passwordRecoveryReducer";
import Loading from "./Loading";
import TaxnumberForm from "./TaxnumberForm";
import ValidationCode from "./ValidationCode";
import { maskPhoneNumber } from "../utils/helper";
import Notification from "./Notification";

const PasswordRecovery = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { user, isFetching } = useSelector((state) => state.passwordRecovery);
  const [userNotFoundNotification, setUserNotFoundNotification] = useState("");

  const checkPhoneHandler = async (values) => {
    try {
      dispatch(userDataRequest());
      const data = await registrationService.getUserPhone(values);
      dispatch(userDataReceived(data));
    } catch (error) {
      if (
        error.response &&
        error.response.status === 422 &&
        error.response?.data?.message
      ) {
        dispatch(userDataError());
        return error.response.data.message;
      } else if (error.response && error.response.status === 404) {
        dispatch(userDataError());
        setUserNotFoundNotification(
          error.response?.data?.message
            ? error.response.data.message
            : t("User error message")
        );
      } else {
        dispatch(userDataError(error));
      }
    }
  };

  const handleNotMyPhone = async () => {
    try {
      dispatch(userDataRequest());
      await registrationService.deleteUser();
      dispatch(userDataReceived(null));
      history.push("/");
    } catch (error) {
      dispatch(userDataError(error));
    }
  };

  return (
    <>
      {userNotFoundNotification && (
        <Notification
          title={t("Error")}
          handleClose={() => setUserNotFoundNotification("")}
          message={userNotFoundNotification}
          type="Error"
          buttons={[
            {
              name: t("Registration"),
              href: `${APP_PREFIX}/`,
              className: "button",
            },
          ]}
        />
      )}
      <div className="recover-password-info">
        {isFetching && <Loading />}
        <div className="divider divider_top">
          <h1>{t("Password recovery")}</h1>
        </div>
        {user ? (
          user.passwordStatus ? (
            <>
              {t("You are already registered.")}
              <div className="confirmation__button">
                <a
                  href={URL_AFTER_REGISTRATION}
                  rel="noopener noreferrer"
                  className="button"
                >
                  {t("Login again")}
                </a>
              </div>
            </>
          ) : (
            user.taxNumber &&
            user.phone && (
              <div className="recover-password-info">
                <div className="recover-password-info__row">
                  <div className="recover-password-info__label">
                    {t("Tax number")}:
                  </div>
                  <div className="recover-password-info__value">
                    {" "}
                    {user.taxNumber}
                  </div>
                </div>
                <div className="recover-password-info__row">
                  <div className="recover-password-info__label">
                    {t("Phone number")}:
                  </div>
                  <div className="recover-password-info__value">
                    {maskPhoneNumber(user.phone)}
                  </div>
                </div>
                {user.validationCodeStatus ? (
                  <div className="recover-password-info__validation-code">
                    <ValidationCode
                      onClick={(validationCode) =>
                        dispatch(confirmValidationCode(validationCode))
                      }
                    />
                  </div>
                ) : (
                  <div className="recover-password-info__confirmation">
                    {t("I confirm that this is my phone number.")}
                    <div className="recover-password-info__buttons">
                      <button
                        onClick={() => dispatch(sendValidationCode())}
                        className="button recover-password-info__buttons_spaced"
                      >
                        {t("Yes")}
                      </button>
                      <button onClick={handleNotMyPhone} className="button">
                        {t("No")}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )
          )
        ) : (
          <div className="container">
            <TaxnumberForm onSubmit={checkPhoneHandler} />
          </div>
        )}
      </div>
    </>
  );
};

export default PasswordRecovery;
