import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const ConfirmPassword = ({ onClick }) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="confirm-password">
      <div className="confirm-password__title">
        {t("Please confirm your password")}
      </div>
      <div className="confirm-password__line">
        <div className="confirm-password__element">
          <input
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          ></input>
        </div>
      </div>
      <div className="confirm-password__line">
        <div className="confirm-password__element">
          <input
            type="checkbox"
            value={showPassword}
            onChange={() => setShowPassword(!showPassword)}
          ></input>
          <label className="confirm-password__label">
            {t("Show password")}
          </label>
        </div>
      </div>

      <button
        className={`button confirm-password__submit ${
          password.length === 0 ? "disabled" : ""
        }`}
        onClick={() => onClick(password)}
      >
        {t("Confirm")}
      </button>
    </div>
  );
};

export default ConfirmPassword;
