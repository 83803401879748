import React, { useEffect, useState, useRef } from "react";

const Notification = ({ title, message, handleClose, type, buttons }) => {
  const ref = useRef(null);
  const [refVisible, setRefVisible] = useState(false);
  const [position, setPosition] = useState({ left: 0, top: 0 });

  const positionStyle = {
    position: "fixed",
    left: position.left,
    top: position.top,
  };

  const updatePosition = () => {
    if (ref.current) {
      setPosition({
        left: Math.abs(window.innerWidth - ref.current.offsetWidth) / 2,
        top: (window.innerHeight - ref.current.offsetHeight) / 2.5,
      });
    }
  };

  useEffect(() => {
    if (!refVisible) {
      return;
    }
    updatePosition();

    //const timer = setTimeout(() => { handleClose() }, 7000);
    //return ()=>clearTimeout(timer)
  }, [refVisible]);

  useEffect(() => {
    window.addEventListener("resize", updatePosition);
    return () => {
      window.removeEventListener("resize", updatePosition);
    };
  }, []);

  let msg;
  try {
    msg = JSON.parse(message);
  } catch (err) {
    msg = message;
  }

  return (
    <div
      className="flash"
      style={positionStyle}
      ref={(el) => {
        ref.current = el;
        setRefVisible(!!el);
      }}
    >
      <div
        className={`flash__header ${
          type === "Error"
            ? "flash__header_error"
            : "flash__header_notification"
        }`}
      >
        <div className="flash__title">{title}</div>
        <button
          className="flash__close-btn button button-icon"
          onClick={() => handleClose()}
        >
          {
            "x"
            //<i className="icon-cross"></i>
          }
        </button>
      </div>
      <div className="flash__container">
        {typeof msg == "object" && Array.isArray(msg.error) && (
          <ul className="flash__message-list flash__message-list_error">
            {msg.error.map((error_msg, i) => (
              <li key={i}>{error_msg}</li>
            ))}
          </ul>
        )}
        {typeof msg == "object" && Array.isArray(msg.notice) && (
          <ul className="flash__message-list flash__message-list_notice">
            {msg.notice.map((notice_msg, i) => (
              <li key={i}>{notice_msg}</li>
            ))}
          </ul>
        )}
        {typeof msg == "object" && Array.isArray(msg.message) && (
          <ul className="flash__message-list">
            {msg.message.map((message_msg, i) => (
              <li key={i}>{message_msg}</li>
            ))}
          </ul>
        )}
        {typeof msg == "string" && <div className="flash__text">{message}</div>}
        {buttons &&
          buttons.map(
            (btn, i) =>
              btn?.href && (
                <div className="flash__button" key={i}>
                  <a
                    href={btn.href}
                    className={btn.className ? btn.className : ""}
                  >
                    {btn.name}
                  </a>
                </div>
              )
          )}
      </div>
    </div>
  );
};

export default Notification;
