import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import fiJson from "../../../server/src/translations/fi/general_messages.fi.json";
import enJson from "../../../server/src/translations/en/general_messages.en.json";
import svJson from "../../../server/src/translations/sv/general_messages.sv.json";
import ruJson from "../../../server/src/translations/ru/general_messages.ru.json";

i18n.use(initReactI18next).init({
  resources: {
    fi: {
      translation: fiJson,
    },
    en: {
      translation: enJson,
    },
    sv: {
      translation: svJson,
    },
    ru: {
      translation: ruJson,
    },
  },
  fallbackLng: "fi",
});

export default i18n;
