export const CHECK_REGISTRATION_STATE_API = "/app-api";
export const FETCH_USER_DATA_API = "/app-api/ssn";
export const AGREEMENT_API = "/app-api/agreement";
export const SIGN_AGREEMENT_API = "/app-api/agreement/sign";
export const AGREEMENT_STATUS_API = "/app-api/agreement/status";
export const SIGNED_AGREEMENT_API = "/app-api/agreement/signed";
export const AUTH_PROVIDERS_API = "/app-api/agreement/auth-providers";
export const SEND_PASSWORD_API = "/app-api/password/send";
export const CONFIRM_PASSWORD_API = "/app-api/password/confirm";
export const RESEND_PASSWORD_API = "/app-api/password/resend";
export const PASSWORD_RECOVER_API = "/app-api/password/recover";
export const CONFIRM_VALIDATION_CODE_API = "/app-api/validation-code/confirm";
export const ADD_USER_API = "/app-api/add-user";
export const DELETE_USER_API = "/app-api/delete-user";
export const LANGUAGE_API = "/app-api/language";
