import React from "react";
import { Form, Field } from "react-final-form";
import { useTranslation } from "react-i18next";

const SSNForm = ({ onSubmit }) => {
  const { t } = useTranslation();
  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} className="user-form">
          <div>
            <Field name="ssn" /*validate={required}*/>
              {({ input, meta }) => {
                return (
                  <div className="user-form__line">
                    <label className="user-form__label">{t("SSN")}</label>
                    <div className="user-form__element">
                      <input
                        {...input}
                        autoComplete="off"
                        type="text"
                        className={
                          (meta.error || meta.submitError) && meta.touched
                            ? "input-error"
                            : ""
                        }
                      />
                      {(meta.error || meta.submitError) && meta.touched && (
                        <div className="user-form__error">
                          {meta.error || meta.submitError}
                        </div>
                      )}
                    </div>
                  </div>
                );
              }}
            </Field>

            <Field name="phone" /*validate={required}*/>
              {({ input, meta }) => {
                return (
                  <div className="user-form__line">
                    <label className="user-form__label">
                      {t("Phone number")}
                    </label>
                    <div className="user-form__element">
                      <input
                        {...input}
                        autoComplete="off"
                        type="text"
                        //placeholder={t("Phone number")}
                        className={
                          (meta.error || meta.submitError) && meta.touched
                            ? "input-error"
                            : ""
                        }
                      />
                      {(meta.error || meta.submitError) && meta.touched && (
                        <div className="user-form__error">
                          {meta.error || meta.submitError}
                        </div>
                      )}
                    </div>
                  </div>
                );
              }}
            </Field>
          </div>

          <button
            type="submit"
            className="button user-form__submit"
            disabled={submitting}
          >
            {t("Send")}
          </button>
        </form>
      )}
    </Form>
  );
};

export default SSNForm;
