import registrationService from "../services/registration";

const SET_FETCHING = "SET_FETCHING";
const USER_DATA_RECEIVED = "USER_DATA_RECEIVED";
const USER_DATA_ERROR = "USER_DATA_ERROR";
const USER_DATA_UPDATED = "USER_DATA_UPDATED";
const USER_UPDATE_DATA_ERROR = "USER_UPDATE_DATA_ERROR";

export default (
  state = {
    user: null,
    isFetching: false,
  },
  action
) => {
  switch (action.type) {
    case SET_FETCHING:
      return {
        ...state,
        isFetching: action.data,
      };
    case USER_DATA_RECEIVED: {
      return {
        ...state,
        user: action.data,
      };
    }
    case USER_DATA_UPDATED: {
      return {
        ...state,
        user: state.user ? { ...state.user, ...action.data } : action.data,
      };
    }
    case USER_DATA_ERROR:
      return {
        ...state,
        user: null,
      };
    case USER_UPDATE_DATA_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export const setFetching = (data) => ({
  type: SET_FETCHING,
  data,
});

export const userDataError = (error) => {
  return error
    ? {
        type: USER_DATA_ERROR,
        error,
      }
    : {
        type: USER_DATA_ERROR,
      };
};

export const userUpdateDataError = (error) => ({
  type: USER_UPDATE_DATA_ERROR,
  error,
});

export const userDataReceived = (data) => ({
  type: USER_DATA_RECEIVED,
  data,
});

export const userDataUpdated = (data) => ({
  type: USER_DATA_UPDATED,
  data,
});

export const checkRegistrationState = () => {
  return async (dispatch) => {
    try {
      dispatch(setFetching(true));
      let user = await registrationService.checkRegistrationState();
      dispatch(userDataReceived(user));
      if (user.agreementStatus && !user.passwordStatus) {
        await dispatch(checkAgreementStatusAndSendPassword());
      }
    } catch (error) {
      dispatch(userDataError(error));
    } finally {
      dispatch(setFetching(false));
    }
  };
};

export const resetUser = () => {
  return async (dispatch) => {
    try {
      dispatch(setFetching(true));
      await registrationService.resetUser();
      dispatch(userDataReceived(null));
    } catch (error) {
      dispatch(userDataError(error));
    } finally {
      setFetching(false);
    }
  };
};

export const checkAgreementStatusAndSendPassword = () => {
  return async (dispatch) => {
    let user;
    try {
      dispatch(setFetching(true));
      user = await registrationService.checkAgreementStatus();
      dispatch(userDataReceived(user));
      if (user.agreementStatus === "signed") {
        try {
          const { passwordStatus } = await registrationService.sendPassword();
          user = { ...user, passwordStatus };
          dispatch(userDataReceived(user));
        } catch (error) {
          dispatch(userUpdateDataError(error));
        }
      }
    } catch (error) {
      dispatch(userDataError(error));
      throw error;
    } finally {
      dispatch(setFetching(false));
    }
    return user;
  };
};

// Confirm password and create User account
export const confirmPassword = (password) => {
  return async (dispatch) => {
    try {
      dispatch(setFetching(true));
      const user = await registrationService.confirmPassword(password);
      dispatch(userDataReceived(user));
    } catch (error) {
      dispatch(userUpdateDataError(error));
    } finally {
      dispatch(setFetching(false));
    }
  };
};

// Resend password
export const resendPassword = (phone) => {
  return async (dispatch) => {
    try {
      dispatch(setFetching(true));
      let user;
      user = await registrationService.resendPassword(phone);
      if (user?.passwordStatus) {
        dispatch(userDataUpdated(user));
      } else {
        dispatch(userDataReceived(user));
      }
    } catch (error) {
      dispatch(userUpdateDataError(error));
    } finally {
      dispatch(setFetching(false));
    }
  };
};
