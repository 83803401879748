import registrationService from "../services/registration";

const USER_DATA_REQUEST = "USER_DATA_REQUEST";
const USER_DATA_RECEIVED = "USER_DATA_RECEIVED";
const USER_DATA_ERROR = "USER_DATA_ERROR";
const USER_UPDATE_DATA_ERROR = "USER_UPDATE_DATA_ERROR";

export default (
  state = {
    user: null,
    isFetching: false,
  },
  action
) => {
  switch (action.type) {
    case USER_DATA_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case USER_DATA_RECEIVED: {
      return {
        ...state,
        isFetching: false,
        user: action.data,
      };
    }
    case USER_DATA_ERROR:
      return {
        ...state,
        user: null,
        isFetching: false,
      };
    case USER_UPDATE_DATA_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};

export const userDataRequest = () => ({
  type: USER_DATA_REQUEST,
});

export const userDataError = (error) => {
  return error
    ? {
        type: USER_DATA_ERROR,
        error,
      }
    : {
        type: USER_DATA_ERROR,
      };
};

export const userUpdateDataError = (error) => ({
  type: USER_UPDATE_DATA_ERROR,
  error,
});

export const userDataReceived = (data) => ({
  type: USER_DATA_RECEIVED,
  data,
});

// Send an SMS with validation code to confirm phone number
export const sendValidationCode = () => {
  return async (dispatch) => {
    try {
      dispatch(userDataRequest());
      const user = await registrationService.sendValidationCode();
      dispatch(userDataReceived(user));
    } catch (error) {
      dispatch(userDataError(error));
    }
  };
};

// Confirm validation code, generate new password and send an SMS with the password
export const confirmValidationCode = (validationCode) => {
  return async (dispatch) => {
    try {
      dispatch(userDataRequest());
      const user = await registrationService.confirmValidationCode(
        validationCode
      );
      dispatch(userDataReceived(user));
    } catch (error) {
      dispatch(userUpdateDataError(error));
    }
  };
};
