import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  setFetching,
  userDataReceived,
  userDataError,
  resetUser,
  checkRegistrationState,
  checkAgreementStatusAndSendPassword,
  confirmPassword,
  resendPassword,
} from "../reducers/registrationReducer";
import { fetchAuthProvidersList } from "../reducers/authProvidersReducer";
import { setError } from "../reducers/errorReducer";
import SSNForm from "./SSNForm";
import SignedAgreement from "./SignedAgreement";
import ConfirmPassword from "./ConfirmPassword";
import ResendPassword from "./ResendPassword";
import AuthenticationProvidersList from "./AuthenticationProvidersList";
import Loading from "./Loading";
import Notification from "./Notification";
//import Language from "./Language";
//import Hint from "./Hint";
import registrationService from "../services/registration";
import { AGREEMENT_API } from "../config/config";

const Home = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { error } = useSelector((state) => state.error);
  const dispatch = useDispatch();
  const { user, isFetching: isUserFetching } = useSelector(
    (state) => state.registration
  );
  const { authProvidersList, isFetching } = useSelector(
    (state) => state.authProviders
  );
  const [alreadyRegistered, setAlreadyRegistered] = useState("");
  const [resendPwd, setResendPwd] = useState(false);

  useEffect(() => {
    dispatch(checkRegistrationState());
    dispatch(fetchAuthProvidersList());
  }, []);

  const handleSearch = async (values) => {
    try {
      //dispatch(resetUser());
      dispatch(setFetching(true));
      let data = await registrationService.fetchUserData(values);
      dispatch(userDataReceived(data));
      if (data.document_uuid && !data.passwordStatus) {
        await dispatch(checkAgreementStatusAndSendPassword());
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 422 &&
        error.response?.data?.message
      ) {
        return error.response.data.message;
      } else if (error.response && error.response.status === 404) {
        setAlreadyRegistered(
          error.response?.data?.message
            ? error.response.data.message
            : t("User error message")
        );
      } else {
        dispatch(userDataError(error));
      }
    } finally {
      dispatch(setFetching(false));
    }
  };

  const handleSignAgreement = async (authProvider) => {
    try {
      dispatch(setFetching(true));
      const reply = await registrationService.signAgreement({ authProvider });
      if (reply && reply.location) {
        window.location.href = reply.location;
      } else {
        dispatch(userDataError());
      }
    } catch (error) {
      dispatch(userDataError(error));
    } finally {
      setFetching(false);
    }
  };

  const confirmPasswordHandler = async (password) => {
    dispatch(confirmPassword(password));
  };

  const resendPasswordHandler = async (phone) => {
    dispatch(resendPassword(phone));
    setResendPwd(false);
  };

  const [isTermsChecked, setTermsChecked] = useState(false);
  const handleTermsCheckboxChange = (event) => {
    setTermsChecked(event.target.checked);
  };

  return (
    <>
      {(isFetching || isUserFetching) && <Loading />}
      {alreadyRegistered && (
        <Notification
          title={t("Error")}
          handleClose={() => setAlreadyRegistered("")}
          message={alreadyRegistered}
          type="Error"
          buttons={[
            {
              name: t("Login"),
              href: URL_AFTER_REGISTRATION,
              className: "button",
            },
          ]}
        />
      )}
      <div className="divider divider_top">
        <h1>{t("User registration")}</h1>
      </div>
      <div className="user-info">
        {user && user.ssn ? (
          <>
            {(user.passwordStatus || user.agreementStatus === "signed") && (
              <div className="confirmation container">
                {user.passwordStatus === "confirmed" && (
                  <div>{t("You are successfully registered.")}</div>
                )}
                <div>
                  <SignedAgreement />
                </div>
                {user.passwordStatus === "confirmed" && (
                  <div className="confirmation__login-link">
                    {t("you may go forward")}
                    <div className="confirmation__button">
                      <a
                        href={URL_AFTER_REGISTRATION}
                        rel="noopener noreferrer"
                        className="button"
                      >
                        {t("Login again")}
                      </a>
                    </div>
                  </div>
                )}
                {user.passwordStatus === "sent" && (
                  <>
                    <div>
                      {resendPwd ? (
                        <ResendPassword onClick={resendPasswordHandler} />
                      ) : (
                        <ConfirmPassword onClick={confirmPasswordHandler} />
                      )}
                    </div>
                    <div className="divider divider-top"></div>
                    <div>
                      <button
                        className="button button__link resend-password__show"
                        onClick={() => setResendPwd(!resendPwd)}
                      >
                        {resendPwd ? t("Back") : t("Resend password")}
                      </button>
                    </div>
                    <div className="divider"></div>
                  </>
                )}
                {user.passwordStatus === undefined && (
                  <ResendPassword onClick={resendPasswordHandler} />
                )}
              </div>
            )}
            {user.agreementStatus === "created" && !user.passwordStatus && (
              <div className="error container">
                <div className="divider divider_top">
                  <h1>{t("Please wait while we finish registration")}</h1>
                </div>
              </div>
            )}
            {user.agreementStatus === "error" && (
              <div className="error container">
                <div>
                  {t("Sorry, an error occurred")}
                  <div className="error__button">
                    <button
                      onClick={() => dispatch(resetUser())}
                      className="button"
                    >
                      {t("Home page")}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {!user.passwordStatus &&
              (user.agreementStatus === undefined ||
                user.agreementStatus === "pending") && (
                <>
                  <div className="user-info__data">
                    <div className="user-info__row user-info__row_ok">
                      <div className="user-info__label">
                        {t("Social identity number")}:
                      </div>
                      <div className="user-info__value"> {user.ssn}</div>
                    </div>
                    {user.taxNumber && (
                      <div className="user-info__row user-info__row_ok">
                        <div className="user-info__label">
                          {t("Tax number")}:
                        </div>
                        <div className="user-info__value">{user.taxNumber}</div>
                      </div>
                    )}
                    {user.firstName && (
                      <div className="user-info__row user-info__row_ok">
                        <div className="user-info__label">
                          {t("First name")}:
                        </div>
                        <div className="user-info__value">{user.firstName}</div>
                      </div>
                    )}
                    {user.lastName && (
                      <div className="user-info__row user-info__row_ok">
                        <div className="user-info__label">
                          {t("Last name")}:
                        </div>
                        <div className="user-info__value">{user.lastName}</div>
                      </div>
                    )}
                  </div>
                  <div className="divider"></div>
                  <div className="user-info__terms-check">
                    <input
                      type="checkbox"
                      checked={isTermsChecked}
                      onChange={handleTermsCheckboxChange}
                    />
                    <label>
                      {t("I agree with")}{" "}
                      <a
                        target="_blank"
                        href={SERVER_URL + APP_PREFIX + AGREEMENT_API}
                        rel="noreferrer"
                        className="button button__link"
                      >
                        {t("terms of use link text")}
                      </a>{" "}
                      {t("and am ready to sign them.")}
                    </label>
                  </div>
                </>
              )}
          </>
        ) : (
          <div className="user-info__form">
            <SSNForm onSubmit={handleSearch} />
          </div>
        )}
      </div>
      {(user === null ||
        (!user.passwordStatus &&
          (user.agreementStatus === undefined ||
            user.agreementStatus === "pending"))) && (
        <div
          className={isTermsChecked ? "" : "disabled"}
          style={{ pointerEvents: isTermsChecked ? "auto" : "none" }}
        >
          <div className="divider">
            <h2>{t("Digital signing")}</h2>
          </div>
          <AuthenticationProvidersList onSelect={handleSignAgreement} />
        </div>
      )}
    </>
  );
};

export default Home;
