import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className="page404">
      <div>{t("Sorry, the page you are looking for is not found")}</div>
      <div className="page404__button">
        <Link to="/" className="button">
          {t("Home page")}
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
