import registrationService from "../services/registration";

const LANGUAGE_REQUEST = "USER_LANGUAGE_REQUEST";
const LANGUAGE_RECEIVED = "USER_LANGUAGE_RECEIVED";
const LANGUAGE_ERROR = "USER_LANGUAGE_ERROR";

export default (
  state = {
    language: null,
    isFetching: false,
  },
  action
) => {
  switch (action.type) {
    case LANGUAGE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case LANGUAGE_RECEIVED: {
      return {
        ...state,
        isFetching: false,
        language: action.data,
      };
    }
    case LANGUAGE_ERROR:
      return {
        ...state,
        //language: null,
        isFetching: false,
      };
    default:
      return state;
  }
};

export const languageRequest = () => ({
  type: LANGUAGE_REQUEST,
});

export const languageError = (error) => ({
  type: LANGUAGE_ERROR,
  error,
});

export const languageReceived = (data) => ({
  type: LANGUAGE_RECEIVED,
  data,
});

export const fetchLanguage = () => {
  return async (dispatch) => {
    try {
      dispatch(languageRequest());
      const lang = await registrationService.getLanguage();
      dispatch(languageReceived(lang));
    } catch (error) {
      dispatch(languageError(error));
    }
  };
};

export const setLanguage = ({ language }) => {
  return async (dispatch) => {
    try {
      dispatch(languageRequest());
      const lang = await registrationService.setLanguage({ language });
      dispatch(languageReceived(lang));
    } catch (error) {
      dispatch(languageError(error));
    }
  };
};
