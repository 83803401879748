import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const AuthenticationProvidersList = ({ onSelect }) => {
  const { authProvidersList } = useSelector((state) => state.authProviders);

  const authProviders = authProvidersList ? authProvidersList?.methods : null;

  return (
    <div className="auth-provider-list">
      {authProviders &&
        authProviders.map((provider) => (
          <div
            className="auth-provider-list__element"
            key={provider.identifier}
            title={provider.name}
            onClick={() => onSelect(provider.identifier)}
          >
            <img src={provider.image} />
          </div>
        ))}
    </div>
  );
};

export default AuthenticationProvidersList;
