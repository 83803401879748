import React from "react";
import { Form, Field } from "react-final-form";
import { useTranslation } from "react-i18next";

const AddUserForm = ({ onSubmit, onReset, initialValues }) => {
  const { t } = useTranslation();
  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, submitting, form, hasSubmitErrors }) => (
        <form onSubmit={handleSubmit} className="user-form">
          <div>
            <Field name="firstName">
              {({ input, meta }) => {
                return (
                  <div className="user-form__line">
                    <label className="user-form__label">
                      {t("First name")}
                    </label>
                    <div className="user-form__element">
                      <input
                        {...input}
                        autoComplete="off"
                        type="text"
                        className={
                          (meta.error || meta.submitError) && meta.touched
                            ? "input-error"
                            : ""
                        }
                      />
                      {(meta.error || meta.submitError) && meta.touched && (
                        <div className="user-form__error">
                          {meta.error || meta.submitError}
                        </div>
                      )}
                    </div>
                  </div>
                );
              }}
            </Field>
          </div>
          <div>
            <Field name="lastName">
              {({ input, meta }) => {
                return (
                  <div className="user-form__line">
                    <label className="user-form__label">{t("Last name")}</label>
                    <div className="user-form__element">
                      <input
                        {...input}
                        autoComplete="off"
                        type="text"
                        className={
                          (meta.error || meta.submitError) && meta.touched
                            ? "input-error"
                            : ""
                        }
                      />
                      {(meta.error || meta.submitError) && meta.touched && (
                        <div className="user-form__error">
                          {meta.error || meta.submitError}
                        </div>
                      )}
                    </div>
                  </div>
                );
              }}
            </Field>
          </div>
          <div>
            <Field name="taxNumber">
              {({ input, meta }) => {
                return (
                  <div className="user-form__line">
                    <label className="user-form__label">
                      {t("Tax number")}
                    </label>
                    <div className="user-form__element">
                      <input
                        {...input}
                        autoComplete="off"
                        type="text"
                        className={
                          (meta.error || meta.submitError) && meta.touched
                            ? "input-error"
                            : ""
                        }
                      />
                      {(meta.error || meta.submitError) && meta.touched && (
                        <div className="user-form__error">
                          {meta.error || meta.submitError}
                        </div>
                      )}
                    </div>
                  </div>
                );
              }}
            </Field>
          </div>
          <div>
            <Field name="phone">
              {({ input, meta }) => {
                return (
                  <div className="user-form__line">
                    <label className="user-form__label">
                      {t("Phone number")}
                    </label>
                    <div className="user-form__element">
                      <input
                        {...input}
                        autoComplete="off"
                        type="text"
                        className={
                          (meta.error || meta.submitError) && meta.touched
                            ? "input-error"
                            : ""
                        }
                      />
                      {(meta.error || meta.submitError) && meta.touched && (
                        <div className="user-form__error">
                          {meta.error || meta.submitError}
                        </div>
                      )}
                    </div>
                  </div>
                );
              }}
            </Field>
          </div>
          {initialValues?.accountExists && !hasSubmitErrors && (
            <div>
              <div>{t("User account already exists. Check user data.")}</div>
              <Field name="confirmData">
                {({ input, meta }) => {
                  return (
                    <div className="user-form__line">
                      <div className="user-form__element">
                        <input {...input} type="checkbox" />
                        <label>{t("Confirm that user data is correct")}</label>
                      </div>
                    </div>
                  );
                }}
              </Field>
            </div>
          )}
          <div>
            <button
              type="submit"
              className="button user-form__submit"
              disabled={submitting}
            >
              {t("Send")}
            </button>
          </div>
          <div>
            <button
              className="button button__link user-form__submit"
              disabled={submitting}
              onClick={(e) => {
                e.preventDefault();
                onReset();
                form.reset({});
              }}
            >
              {t("New")}
            </button>
          </div>
        </form>
      )}
    </Form>
  );
};

export default AddUserForm;
