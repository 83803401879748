import registrationService from "../services/registration";

const FETCH_AUTH_PROVIDERS_LIST_REQUEST = "FETCH_AUTH_PROVIDERS_LIST_REQUEST";
const FETCH_AUTH_PROVIDERS_LIST_RECEIVED = "FETCH_AUTH_PROVIDERS_LIST_RECEIVED";
const FETCH_AUTH_PROVIDERS_LIST_ERROR = "FETCH_AUTH_PROVIDERS_LIST_ERROR";

export default (
  state = {
    authProvidersList: null,
    isFetching: false,
  },
  action
) => {
  switch (action.type) {
    case FETCH_AUTH_PROVIDERS_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_AUTH_PROVIDERS_LIST_RECEIVED: {
      return {
        ...state,
        authProvidersList: action.data,
        isFetching: false,
      };
    }
    case FETCH_AUTH_PROVIDERS_LIST_ERROR:
      return {
        ...state,
        authProvidersList: null,
        isFetching: false,
      };
    default:
      return state;
  }
};

export const fetchAuthProvidersListRequest = () => ({
  type: FETCH_AUTH_PROVIDERS_LIST_REQUEST,
});

export const fetchAuthProvidersListError = (error) => ({
  type: FETCH_AUTH_PROVIDERS_LIST_ERROR,
  error,
});

export const fetchAuthProvidersListReceived = (data) => ({
  type: FETCH_AUTH_PROVIDERS_LIST_RECEIVED,
  data,
});

export const fetchAuthProvidersList = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchAuthProvidersListRequest());
      const authProviders = await registrationService.fetchAuthProviders();
      dispatch(fetchAuthProvidersListReceived(authProviders));
    } catch (error) {
      dispatch(fetchAuthProvidersListError(error));
    }
  };
};
