import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { resetError } from "../reducers/errorReducer";
import Notification from "./Notification";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ErrorNotification = () => {
  const { error } = useSelector((state) => state.error);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let errorMessage;
  if (typeof error === "string" || error instanceof String) {
    errorMessage = error;
  } else if (error?.response?.data?.message) {
    errorMessage = error.response.data.message;
  } else {
    console.log(JSON.stringify(error));
    errorMessage = t("User error message");
  }

  const handleClose = () => {
    dispatch(resetError());
  };

  return (
    <Modal show={error} onHide={handleClose} animation={false}>
      <Notification
        title={t("Error")}
        handleClose={handleClose}
        message={errorMessage}
        type="Error"
      />
    </Modal>
  );
};

export default ErrorNotification;
