export const SET_ERROR = "SET_ERROR";
export const RESET_ERROR = "RESET_ERROR";

export default (
  state = {
    error: null,
  },
  action
) => {
  if (action.error) {
    return {
      error: action.error,
    };
  } else if (action.type === RESET_ERROR) {
    return {
      error: null,
    };
  }
  return state;
};

export const setError = (error) => ({
  type: SET_ERROR,
  error,
});

export const resetError = () => ({
  type: RESET_ERROR,
});
