import registrationService from "../services/registration";

const USER_DATA_REQUEST = "USER_DATA_REQUEST";
const USER_DATA_RECEIVED = "USER_DATA_RECEIVED";
const USER_DATA_ERROR = "USER_DATA_ERROR";
const USER_DATA_VALIDATION_ERROR = "USER_DATA_VALIDATION_ERROR";
const RESET_USER = "RESET_USER";

export default (
  state = {
    user: null,
    isFetching: false,
  },
  action
) => {
  switch (action.type) {
    case USER_DATA_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case USER_DATA_RECEIVED: {
      return {
        ...state,
        isFetching: false,
        user: action.data,
      };
    }
    case USER_DATA_ERROR:
    case RESET_USER:
      return {
        ...state,
        user: null,
        isFetching: false,
      };
    case USER_DATA_VALIDATION_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};

export const userDataRequest = () => ({
  type: USER_DATA_REQUEST,
});

export const userDataError = (error) => {
  return error
    ? {
        type: USER_DATA_ERROR,
        error,
      }
    : {
        type: USER_DATA_ERROR,
      };
};

export const userDataReceived = (data) => ({
  type: USER_DATA_RECEIVED,
  data,
});

export const resetUser = () => ({
  type: RESET_USER,
});

export const userDataValidationError = () => ({
  type: USER_DATA_VALIDATION_ERROR,
});
