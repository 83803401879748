import React from "react";
const Header = () => {
  //  const { t } = useTranslation();

  return (
    <a href={URL_AFTER_REGISTRATION}>
      <header className="main-header">
        <div className="wrapper"></div>
      </header>
    </a>
  );
};

export default Header;
