import React, { useEffect, useState } from "react";
//import loadingImg from "../images/loading.gif";

const Loading = () => {
  const wrapperStyle = {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    zIndex: "100000",
  };

  /*const style = {
    backgroundImage: `url(${loadingImg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: 200,
    width: 200,
  };*/

  return (
    /*<div style={wrapperStyle}>
      <div style={style}></div>
    </div>
  );*/
    <div className="loading-spinner">
      <div className="loading-spinner__image"></div>
    </div>
  );
};

export default Loading;
