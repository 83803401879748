import React, { useState, createElement } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { FI, GB, SE, RU } from "country-flag-icons/react/3x2";
import Loading from "./Loading";
import { setLanguage } from "../reducers/languageReducer";

const Language = () => {
  const { t } = useTranslation();
  const { error } = useSelector((state) => state.error);
  const dispatch = useDispatch();
  const { language, isFetching } = useSelector((state) => state.language);
  const [languageListVisible, setLanguageListVisible] = useState(false);

  const languageList = {
    fi: {
      icon: FI,
      title: "Finnish",
    },
    en: {
      icon: GB,
      title: "English",
    },
    sv: {
      icon: SE,
      title: "Swedish",
    },
    ru: {
      icon: RU,
      title: "Russian",
    },
  };

  const Flag = ({ lang }) => {
    return createElement(languageList[lang].icon, {
      className: "interface-language__select-language-item pointer",
    });
  };

  const handleSetLanguage = (lang) => {
    dispatch(setLanguage({ language: lang }));
    setLanguageListVisible(false);
  };

  return (
    <>
      {isFetching && <Loading />}
      {language && (
        <div className="interface-language">
          <div
            onClick={() => setLanguageListVisible(!languageListVisible)}
            title={t(languageList[language]?.title)}
          >
            <Flag lang={language} />
          </div>

          {languageListVisible && (
            <ul className="interface-language__list">
              {Object.keys(languageList).map((lang) => {
                if (lang != language) {
                  return (
                    <li key={lang} onClick={() => handleSetLanguage(lang)}>
                      <div title={t(languageList[lang]?.title)}>
                        <Flag lang={lang} />
                      </div>
                    </li>
                  );
                }
              })}
            </ul>
          )}
        </div>
      )}
    </>
  );
};

export default Language;
