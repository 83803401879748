export const SET_NOTIFICATION = "SET_NOTIFICATION"
export const RESET_NOTIFICATION = "RESET_NOTIFICATION"

export default (state = {
  notification: null
}, action) => {
  switch (action.type) {
    case SET_NOTIFICATION:
      return {
        notification: action.data
      };
    case RESET_NOTIFICATION:
      return {
        notification: null
      };
    default:
      return state;
  }
}

export const setNotification = (data) => ({
  type: SET_NOTIFICATION,
  data
 })
 
export const resetNotification = () => ({
  type: RESET_NOTIFICATION
})