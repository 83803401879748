/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import axios from "axios";
import store from "./store";
// import './styles/app.css'
import App from "./App";
import "./scss/index.scss";
import "./utils/i18n";
import { setNotification } from "./reducers/notificationReducer";
import { setError } from "./reducers/errorReducer";

axios.defaults.timeout = TIMEOUT ? TIMEOUT * 1000 : 30000;
axios.interceptors.response.use((response) => {
  if (
    response.headers["content-type"] &&
    response.headers["content-type"].includes("application/json")
  ) {
    if (response?.data?.message) {
      store.dispatch(setNotification(response.data.message));
    } else if (response?.data?.errorMessage) {
      store.dispatch(setError(response.data.errorMessage));
    }
    if (response?.data && response.data.hasOwnProperty("data")) {
      response.data = response.data.data;
    }
  }
  return response;
}, undefined);

ReactDOM.render(
  <Provider store={store}>
    <Router basename={APP_PREFIX}>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);
