import React, { useState, useEffect } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Header from "./components/Header";
import Language from "./components/Language";
import Home from "./components/Home";
import AddUser from "./components/AddUser";
import NotFound from "./components/NotFound";
import ConfirmationNotification from "./components/ConfirmationNotification";
import ErrorNotification from "./components/ErrorNotification";
import PasswordRecovery from "./components/PasswordRecovery";
import { fetchLanguage } from "./reducers/languageReducer";

const App = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { notification } = useSelector((state) => state.notification);
  const { error } = useSelector((state) => state.error);
  const { language } = useSelector((state) => state.language);
  const location = useLocation();

  /* due to router cuts prefix (basename) before handling */
  const shouldShowNotFound =
    location.pathname === "/" &&
    APP_PREFIX.replace(/\/$/, "") !==
      window.location.pathname.replace(/\/$/, "");

  useEffect(() => {
    dispatch(fetchLanguage());
  }, []);

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  return (
    <>
      {notification && <ConfirmationNotification />}
      {error && <ErrorNotification />}
      <Header />
      <div className="wrapper">
        <div className="main-container">
          <Language />
          <Switch>
            <Route exact path="/addUser">
              <AddUser />
            </Route>
            <Route exact path="/passwordRecover">
              <PasswordRecovery />
            </Route>
            <Route exact path="/">
              {shouldShowNotFound ? <NotFound /> : <Home />}
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  );
};

export default App;
